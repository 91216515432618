import {Serializer} from '@matchsource/api-utils';
import {BundleSearch} from '@matchsource/api-generated/search-match';
import {toParams, toSearchCriteria} from './utils';
import {DonorSearchParams, SearchModel, SearchStatus} from '@matchsource/models/search';
import {CordSearchParams} from '@matchsource/models/cord';

export const bundleSearchSerializer: Serializer<SearchModel, BundleSearch> = {
  fromDTO(input: BundleSearch): SearchModel {
    return {
      sourceSearches: input.sourceSearches.map(sourceSearch => ({
        sourceType: sourceSearch.sourceType,
        status: sourceSearch.status as SearchStatus,
        searchCompleteDate: sourceSearch.searchCompleteDate,
        viewed: sourceSearch.viewed,
        abOnly: sourceSearch.abOnly,
        hapSearchStatus: sourceSearch.hapSearchStatus as SearchStatus,
        parameter: sourceSearch.parameter as DonorSearchParams | CordSearchParams,
        patientId: sourceSearch.patientId,
        phenotypeNum: sourceSearch.phenotypeNum,
        positionInQueue: sourceSearch.positionInQueue,
        searchBundleGuid: sourceSearch.searchBundleGuid,
        searchGuid: sourceSearch.searchGuid,
      })),
      parameters: input.parameters,
      phenotype: input.phenotypeNum,
      patientId: input.patientId,
      maintenance: input.maintenance,
      abOnly: input.abOnly,
      searchGuid: input.searchGuid,
      searchCriteria: toSearchCriteria(input.parameters),
    };
  },

  toDTO(input: SearchModel): BundleSearch {
    return {
      searchGuid: input.searchGuid,
      abOnly: input.abOnly,
      maintenance: input.maintenance,
      patientId: input.patientId,
      phenotypeNum: input.phenotype,
      parameters: toParams(input.searchCriteria),
    };
  },
};
