import {Injectable} from '@angular/core';
import {SearchControllerService} from '@matchsource/api-generated/search-match';
import {bundleSearchSerializer} from './bundle-search.serializer';
import {SearchSerializer} from './search-serializer.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {searchCriteriaSerializer} from '@matchsource/api/patient-shared';
import {SearchStateModel} from '@matchsource/models/search-states';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import flow from 'lodash-es/flow';
import {HttpContext} from '@angular/common/http';
import {SearchModel} from '@matchsource/models/search';
import {silentError, useSpinner} from '@matchsource/core';

type StatusLiteral = 'RUNNING' | 'COMPLETED' | 'FAILED' | 'HALTED' | 'PENDING' | 'INQUEUE' | 'INPROGRESS' | 'DEFERRED';

@Injectable({
  providedIn: 'root',
})
export class SearchApiService {
  constructor(
    private readonly serializer: SearchSerializer,
    private readonly searchController: SearchControllerService,
    private readonly searchControllerService: SearchControllerService
  ) {}

  private static getLoadingCustomErrorHandling(): (context?: HttpContext) => HttpContext {
    return setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingSearches);
  }

  getPatientSearches(patientId: MsApp.Guid, silent = false): Observable<SearchModel[]> {
    const customErrorHandlingContext = SearchApiService.getLoadingCustomErrorHandling();
    const context = flow(customErrorHandlingContext, silentError(silent), useSpinner(silent));

    return this.searchControllerService
      .getLatestSearch({recipientId: patientId}, {context: context()})
      .pipe(map(searches => searches.map(search => this.serializer.fromDTO(search))));
  }

  retrieveSearches(
    params: {
      status: StatusLiteral[];
      viewedInd?: boolean;
      recipientGuid?: string;
    },
    silent = false
  ): Observable<SearchModel[]> {
    const customErrorHandlingContext = SearchApiService.getLoadingCustomErrorHandling();
    const context = flow(customErrorHandlingContext, silentError(silent), useSpinner(silent));
    return this.searchController
      .retrieveSearches(params, {context: context()})
      .pipe(map(searches => searches.map(search => bundleSearchSerializer.fromDTO(search))));
  }

  getSearches(
    patientId: MsApp.Guid,
    phenotypes: string[] | number[],
    ignorePhenotypes = false,
    silent = false
  ): Observable<SearchStateModel[]> {
    return this.getPatientSearches(patientId, silent).pipe(
      map(searches => {
        const models = searches.map(
          item =>
            new SearchStateModel({
              ...item,
              // TODO: fix type
              searchCriteria: searchCriteriaSerializer.fromDTO(item.parameters as any),
            })
        );
        if (ignorePhenotypes) {
          return models;
        }
        return phenotypes.map(
          index => models.find(item => item.index === +index) || new SearchStateModel({phenotype: +index})
        );
      })
    );
  }
}
