import {Serializer} from '@matchsource/api-utils';
import {AdvancedPatientLookupModel, PatientStatus} from '@matchsource/models/patient';
import {fullName} from '@matchsource/api-utils';
import {AdvancedPatientLookupDTO} from './declarations';

export const advancedPatientLookupSerializer: Serializer<AdvancedPatientLookupModel, AdvancedPatientLookupDTO> = {
  fromDTO(input: AdvancedPatientLookupDTO): AdvancedPatientLookupModel {
    return {
      id: input.guid,
      nmdpId: input.nmdpId,
      name: fullName({
        firstName: input.firstName,
        lastName: input.lastName,
      }),
      firstName: input.firstName,
      lastName: input.lastName,
      tcGuid: input.tcGuid,
      localId: input.localId,
      blocked: input.blocked,
      secondaryCode: input.secondaryCode,
      dob: input.birthDate,
      sex: input.sex,
      age: input.age,
      diagnosis: input.diagnosis,
      status: input.status,
      inProgress: input.status === PatientStatus.InProgress,
      coordinator: fullName(input.coordinator),
      legacyCoordinatorName: input.legacyCoordinatorName,
      physician: input.physician,
      prlmDate: input.prlmDate,
      frmlDate: input.frmlDate,
      transferDate: input.transferCompleteDate,
      transferSourceBpGuid: input.transferSourceBpGuid,
      crid: input.crid,
    };
  },
};
