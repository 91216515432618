import {SearchParameter} from '@matchsource/api-generated/subject';
import {Serializer} from '@matchsource/api-utils';
import {
  BdpThreshold,
  BdpThresholdLiteral,
  CordThreshold,
  DonorThresholdLiteral,
  SearchCriteriaModel,
} from '@matchsource/models/patient';

const DEFAULT_CORD_THRESHOLD = CordThreshold.FourOfSix;

const DEFAULT_BDP_THRESHOLD = BdpThreshold.FourOfEight;

const DEFAULT_SEARCH_CRITERIA: SearchCriteriaModel = {
  donors: false,
  cords: false,
  bdps: false,
  donorsMatchThreshold: null,
  bdpsMatchThreshold: null,
  deprioritizeSearch: false,
};

export const searchCriteriaSerializer: Serializer<SearchCriteriaModel, SearchParameter[]> = {
  fromDTO(input: SearchParameter[]): SearchCriteriaModel | null {
    if (!input || input.length === 0) {
      return DEFAULT_SEARCH_CRITERIA;
    }

    const [{deprioritized: deprioritizeSearch = false} = {}] = input;

    return input.reduce(
      (searchCriteria, searchParams) => {
        if (searchParams.sourceType === 'CORD') {
          searchCriteria.cords = true;
        } else if (searchParams.sourceType === 'DONOR') {
          searchCriteria.donors = true;
          searchCriteria.donorsMatchThreshold = searchParams.matchThreshold as DonorThresholdLiteral;
        } else if (searchParams.sourceType === 'BDP') {
          searchCriteria.bdps = true;
          searchCriteria.bdpsMatchThreshold = searchParams.matchThreshold as BdpThresholdLiteral;
        }

        return searchCriteria;
      },
      {...DEFAULT_SEARCH_CRITERIA, deprioritizeSearch}
    );
  },

  toDTO(model: SearchCriteriaModel): SearchParameter[] {
    const dto: SearchParameter[] = [];
    if (!model) {
      return dto;
    }

    const deprioritized = !!model.deprioritizeSearch;

    if (model.donors) {
      dto.push({
        sourceType: 'DONOR',
        matchThreshold: model.donorsMatchThreshold,
        deprioritized,
      });
    }

    if (model.cords) {
      dto.push({
        sourceType: 'CORD',
        matchThreshold: DEFAULT_CORD_THRESHOLD,
        deprioritized,
      });
    }

    if (model.bdps) {
      dto.push({
        sourceType: 'BDP',
        matchThreshold: model.bdpsMatchThreshold ? model.bdpsMatchThreshold : DEFAULT_BDP_THRESHOLD,
        deprioritized,
      });
    }
    return dto;
  },
};
