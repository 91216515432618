import {Serializer} from '@matchsource/api-utils';
import {isDateValid} from '@matchsource/utils';
import isString from 'lodash-es/isString';
import {fromDateToISO} from '@matchsource/date';
import {AdvancedPatientFilterDTO, AdvancedPatientFilterModel} from '@matchsource/models/patient';

export const advancedPatientLookupFiltersSerializer: Serializer<AdvancedPatientFilterModel, AdvancedPatientFilterDTO> =
  {
    toDTO(model: AdvancedPatientFilterModel): AdvancedPatientFilterDTO {
      if (isString(model.value)) {
        if (!isDateValid(model.value)) {
          return {...model};
        }

        return {type: model.type, value: fromDateToISO(model.value)};
      }

      const {localDate, timeZoneOffSetInMinutes} = model.value as {
        localDate: MsApp.DateTimeString;
        timeZoneOffSetInMinutes: number;
      };
      return {
        type: model.type,
        value: {localDate: fromDateToISO(localDate), timeZoneOffSetInMinutes},
      };
    },
  };
