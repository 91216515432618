import {PatientPartiallyUpdatedData} from '@matchsource/models/patient';
import {MapTo} from '@matchsource/api-utils';

const MODEL_KEYS_TO_DTO_KEYS_MAP: Record<keyof PatientPartiallyUpdatedData, string> = {
  isVerified: 'IDENTITY_VERIFIED_IND',

  chainOfIdentity: 'CHAIN_OF_IDENTITY',
};

export const mapPatientPartiallyModelToDto: MapTo<PatientPartiallyUpdatedData, {[key: string]: string}> = model =>
  Object.entries(MODEL_KEYS_TO_DTO_KEYS_MAP).reduce((dto, [modelKey, dtoKey]) => {
    if (modelKey in model) {
      dto[dtoKey] = `${model[modelKey]}`;
    }

    return dto;
  }, {});
