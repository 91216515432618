export enum PatientProfileTypes {
  Positive = 'Positive',
  Negative = 'Negative',
}

export enum HasPhone {
  Yes = 'Y',
  No = 'N',
}

export const ACTUAL_PHENOTYPE_INDEX = 1;
