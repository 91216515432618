import {MapTo} from '@matchsource/api-utils';
import {hlaSerializer} from '@matchsource/api/hla';

const formatHlaData = (data: any, index: number) =>
  Object.entries(data || {}).reduce(
    (ptr, [locus, value]) => {
      const item = hlaSerializer.createHlaModel({locus, ...(value as object)});
      ptr[locus] = item;
      ptr.list.push(item);
      return ptr;
    },
    {index, list: []}
  );

export const ptrLegacyDtoToModel: MapTo<any, any> = (dto: any, phenotypeIndex: number): any =>
  formatHlaData(dto, phenotypeIndex);
