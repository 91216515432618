import {Serializer} from '@matchsource/api-utils';
import {PatientLookupDataSetModel, PatientLookupModel} from '@matchsource/models/patient';
import {createPaginatedDataSet} from '@matchsource/api-utils';
import {patientLookupSerializer} from './patient-lookup.serializer';
import {PatientLookupDTO, PatientLookupResponse} from './declarations';

export const patientLookupSetSerializer: Serializer<PatientLookupDataSetModel, PatientLookupResponse> = {
  fromDTO(input: PatientLookupResponse): PatientLookupDataSetModel {
    return createPaginatedDataSet<PatientLookupModel, PatientLookupDTO>(input, patientLookupSerializer);
  },
};
