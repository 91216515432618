import {Parameter} from '@matchsource/api-generated/search-match';
import {SearchModel, SearchStatus} from '@matchsource/models/search';
import {BdpThreshold, CordThreshold, SearchCriteriaModel} from '@matchsource/models/patient';

export const FINISHED_SEARCH_STATUSES = [SearchStatus.Failed, SearchStatus.Completed];
export const ACCESSIBLE_SEARCH_STATUSES = [...FINISHED_SEARCH_STATUSES, SearchStatus.Deferred];

export const hasAccessibleSearch = ({sourceSearches}: SearchModel) =>
  sourceSearches.some(item => ACCESSIBLE_SEARCH_STATUSES.includes(item.status));

const DEFAULT_CORD_THRESHOLD = CordThreshold.FourOfSix;

const DEFAULT_BDP_THRESHOLD = BdpThreshold.FourOfEight;

const DEFAULT_SEARCH_CRITERA: SearchCriteriaModel = {
  donors: false,
  cords: false,
  bdps: false,
  donorsMatchThreshold: null,
  bdpsMatchThreshold: null,
};

export const toSearchCriteria = (params: Array<Parameter>) => {
  if (!params || params.length === 0) {
    return null;
  }

  const [{deprioritized: deprioritizeSearch = false} = {}] = params;

  return params.reduce(
    (searchCriteria, searchParams) => {
      if (searchParams.sourceType === 'CORD') {
        searchCriteria.cords = true;
      } else if (searchParams.sourceType === 'DONOR') {
        searchCriteria.donors = true;
        searchCriteria.donorsMatchThreshold = searchParams.matchThreshold as any;
      } else if (searchParams.sourceType === 'BDP') {
        searchCriteria.bdps = true;
      }

      return searchCriteria;
    },
    {...DEFAULT_SEARCH_CRITERA, deprioritizeSearch}
  );
};

export const toParams = (searchCriteria: SearchCriteriaModel | null) => {
  const searchParameters: Array<Parameter> = [];
  if (!searchCriteria) {
    return searchParameters;
  }

  const deprioritized = !!searchCriteria.deprioritizeSearch;

  if (searchCriteria.donors) {
    searchParameters.push({
      sourceType: 'DONOR',
      matchThreshold: searchCriteria.donorsMatchThreshold,
      deprioritized,
    });
  }

  if (searchCriteria.cords) {
    searchParameters.push({
      sourceType: 'CORD',
      matchThreshold: DEFAULT_CORD_THRESHOLD,
      deprioritized,
    });
  }

  if (searchCriteria.bdps) {
    searchParameters.push({
      sourceType: 'BDP',
      matchThreshold: DEFAULT_BDP_THRESHOLD,
      deprioritized,
    });
  }

  return searchParameters;
};
