import {Serializer} from '@matchsource/api-utils';
import {fromDateToISO} from '@matchsource/date';
import {PatientUniqueDataSet, PatientUniqueDataSetDTO} from '@matchsource/models/patient';

export const patientUniqueDataSetSerializer: Serializer<PatientUniqueDataSet, PatientUniqueDataSetDTO> = {
  toDTO(input: PatientUniqueDataSet): PatientUniqueDataSetDTO {
    return {
      recipientGuid: input.id,
      firstName: input.firstName,
      lastName: input.lastName,
      dateOfBirth: fromDateToISO(input.birthDate),
      localId: input.localId,
      refId: input.refId,
      processCode: input.patientProcess,
      tcId: input.tcId,
    };
  },
};
