import {Serializer} from '@matchsource/api-utils';
import {PatientLookupModel, PatientStatus} from '@matchsource/models/patient';
import {fullName} from '@matchsource/api-utils';
import {PatientLookupDTO} from './declarations';

export const patientLookupSerializer: Serializer<PatientLookupModel, PatientLookupDTO> = {
  fromDTO(input: PatientLookupDTO): PatientLookupModel {
    return {
      generated: input.generated,
      published: input.published,
      id: input.id,
      rid: input.rid,
      name: fullName(input.name),
      lastName: input.name.lastName,
      firstName: input.name.firstName,
      dob: input.birthDay,
      sex: input.sex,
      diagnosis: input.diagnosis,
      coordinator: fullName(input.caseManagerName),
      createdDate: input.dateCreated,
      status: input.status,
      inProgress: input.status === PatientStatus.InProgress,
      localId: input.localId,
      tcId: input.tcId,
      tccId: input.tccId,
      secondaryCode: input.secondaryCode,
      coreSubmissionFailed: input.coreSubmissionFailed,
      crid: input.crid,
      hasReferralPlan: input.hasReferralPlan,
      hasRelatedFamilyMembers: input.hasRelatedFamilyMembers,
    };
  },
};
