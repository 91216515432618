import {Serializer} from '@matchsource/api-utils';
import {RaceEthnicityHistory} from '@matchsource/api-generated/subject';
import {RaceEthnicityHistoryModel} from '@matchsource/models/workflow-manager';

export const raceEthnicitySerializer: Serializer<RaceEthnicityHistoryModel, RaceEthnicityHistory> = {
  fromDTO(input: RaceEthnicityHistory): RaceEthnicityHistoryModel {
    return {
      id: input.id,
      prevRaceEthn: input.prevRaceEthn,
      currRaceEthn: input.currRaceEthn,
    };
  },
};
