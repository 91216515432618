import {ShareSearchSummaryReport} from '@matchsource/api-generated/subject';

export const getMostRecentShareSearchSummaryReport = (ssr: ShareSearchSummaryReport[]): ShareSearchSummaryReport => {
  const hasItems = ssr?.length > 0;
  if (!hasItems) {
    return {bpGuid: null, bpName: null, bpEmail: null};
  }
  // The server returns only one item in the array. In case we start getting the full history,
  // depending on how the server orders the items in the array, we must either get the first or the last
  // If the server puts the most recent first
  return ssr[0];
  // If the server puts the most recent last
  // return ssr[ssr.length - 1];
};
