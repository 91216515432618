import {formatDateTimeToDate, formatViewDateLocal, toTimestamp} from '@matchsource/date';
import {ensureArray, parseSourceType} from '@matchsource/utils';
import {SearchModel, SearchStatus, SearchStatusModel} from '@matchsource/models/search';
import {SearchCriteriaModel} from '@matchsource/models/patient';
import keyBy from 'lodash-es/keyBy';
import {SourceType} from '@matchsource/models/source';

const FINISHED_SEARCH_STATUSES = [SearchStatus.Failed, SearchStatus.Completed];
const ACCESSIBLE_SEARCH_STATUSES = [...FINISHED_SEARCH_STATUSES, SearchStatus.Deferred];

/**********************************************************************************************
 * Search & Match Type Model
 **********************************************************************************************/
export class SearchStateModel {
  readonly date: string;
  readonly endDateFormatted: string;
  readonly guid: MsApp.Guid;
  readonly id: string;
  readonly patientId: string;
  readonly index: number;
  readonly hasABOnly: boolean;
  readonly hasSearch: boolean;
  readonly isDisabled: boolean;
  readonly maintained: boolean;
  // SearchStatus can't be used as 1st type for Record
  readonly searchStatuses: Record<string, SearchStatusModel>;
  readonly searchCriteria: SearchCriteriaModel;
  readonly sourceSearches: SearchStatusModel[];

  /******************************************************************************************
   * Initialization
   ******************************************************************************************/
  constructor(data: Partial<SearchModel>, pattern: SearchStateModel = null) {
    if (pattern && !data) {
      this.date = pattern.date;
      this.endDateFormatted = pattern.endDateFormatted;
      this.guid = pattern.guid;
      this.id = pattern.id;
      this.patientId = pattern.patientId;
      this.index = pattern.index;
      this.hasABOnly = pattern.hasABOnly;
      this.hasSearch = pattern.hasSearch;
      this.isDisabled = pattern.isDisabled;
      this.maintained = pattern.maintained;
      this.searchStatuses = pattern.searchStatuses;
      this.searchCriteria = pattern.searchCriteria;
      this.sourceSearches = pattern.sourceSearches;
      return;
    }

    this.id = null;
    this.index = 0;
    this.searchStatuses = null;
    this.date = null;
    this.hasABOnly = false;
    this.isDisabled = false;

    if (data) {
      this.id = data.searchGuid;
      this.guid = data.searchGuid;
      this.index = data.phenotype;
      this.searchStatuses = data.sourceSearches ? keyBy<SearchStatusModel>(data.sourceSearches, 'sourceType') : {};
      this.sourceSearches = data.sourceSearches;
      this.patientId = data.patientId;

      this.hasABOnly = !!data.abOnly;
      this.isDisabled = Object.values(this.searchStatuses).some(({status}) =>
        [SearchStatus.InQueue, SearchStatus.Pending, SearchStatus.Running].includes(status)
      );
      this.maintained = data.maintenance && data.maintenance.maintained;
      this.endDateFormatted = '';

      const endDate = data.maintenance && data.maintenance.lastSearchRunDate;
      if (endDate) {
        this.endDateFormatted = formatDateTimeToDate(endDate);
      }

      this.searchCriteria = data.searchCriteria;
    }

    this.hasSearch = !!this.id;
  }

  static createFrom(pattern: SearchStateModel): SearchStateModel {
    return new SearchStateModel(null, pattern);
  }

  /******************************************************************************************
   * COMPUTED VALUES & REFERENCES
   ******************************************************************************************
   * formatted date value
   ******************************************************************************************/
  get dateFormatted(): string {
    return formatViewDateLocal(this.date);
  }

  get completeDate() {
    const [{searchCompleteDate}] = Object.values(this.searchStatuses).sort(
      (search1, search2) => toTimestamp(search1.searchCompleteDate) - toTimestamp(search2.searchCompleteDate)
    );

    return searchCompleteDate;
  }

  hasParticularSearch(sourceType: SourceType): boolean {
    return !!this.searchStatuses && sourceType in this.searchStatuses;
  }

  getParticularSearch(sourceType: SourceType): SearchStatusModel {
    const parsedSourceType = parseSourceType(sourceType);
    return this.hasParticularSearch(parsedSourceType as SourceType) ? this.searchStatuses[parsedSourceType] : null;
  }

  someSearchInStatus(statusList: SearchStatus[] | SearchStatus): boolean {
    statusList = ensureArray(statusList);

    return Object.values(this.searchStatuses).some(({status}) => statusList.includes(status));
  }

  allSearchesInStatuses(statusList: SearchStatus[] | SearchStatus): boolean {
    const searchStatuses = Object.values(this.searchStatuses);

    if (searchStatuses.length === 0) {
      return false;
    }

    statusList = ensureArray(statusList);

    return searchStatuses.every(({status}) => statusList.includes(status));
  }

  get hasDonorSearch(): boolean {
    return this.hasParticularSearch('DONOR');
  }

  get hasCordSearch(): boolean {
    return this.hasParticularSearch('CORD');
  }

  get hasBdpSearch(): boolean {
    return this.hasParticularSearch('BDP');
  }

  get donorSearch(): SearchStatusModel {
    return this.getParticularSearch('DONOR');
  }

  get cordSearch(): SearchStatusModel {
    return this.getParticularSearch('CORD');
  }

  get bdpSearch(): SearchStatusModel {
    return this.getParticularSearch('BDP');
  }

  get isDonorSearchCompleted(): boolean {
    if (!this.hasDonorSearch) {
      return false;
    }
    return FINISHED_SEARCH_STATUSES.includes(this.donorSearch.status);
  }

  get isDonorSearchSucceed(): boolean {
    if (!this.hasDonorSearch) {
      return false;
    }
    return this.donorSearch.status === SearchStatus.Completed;
  }

  get isDonorSearchAccessible(): boolean {
    if (!this.hasDonorSearch) {
      return false;
    }
    return ACCESSIBLE_SEARCH_STATUSES.includes(this.donorSearch.status);
  }

  get isBiobankSearchAccessible(): boolean {
    if (!this.hasBdpSearch) {
      return false;
    }
    return ACCESSIBLE_SEARCH_STATUSES.includes(this.bdpSearch.status);
  }

  get isCordSearchCompleted(): boolean {
    if (!this.hasCordSearch) {
      return false;
    }
    return FINISHED_SEARCH_STATUSES.includes(this.cordSearch.status);
  }

  get isCordSearchSucceed(): boolean {
    if (!this.hasCordSearch) {
      return false;
    }
    return this.cordSearch.status === SearchStatus.Completed;
  }

  get isCordSearchAccessible(): boolean {
    if (!this.hasCordSearch) {
      return false;
    }
    return ACCESSIBLE_SEARCH_STATUSES.includes(this.cordSearch.status);
  }

  get isBdpSearchCompleted(): boolean {
    if (!this.hasBdpSearch) {
      return false;
    }
    return FINISHED_SEARCH_STATUSES.includes(this.bdpSearch.status);
  }

  get isBdpSearchSucceed(): boolean {
    if (!this.hasBdpSearch) {
      return false;
    }
    return this.bdpSearch.status === SearchStatus.Completed;
  }

  get isBdpSearchAccessible(): boolean {
    if (!this.hasBdpSearch) {
      return false;
    }
    return ACCESSIBLE_SEARCH_STATUSES.includes(this.bdpSearch.status);
  }

  get inProgress(): boolean {
    return this.someSearchInStatus(SearchStatus.Running);
  }

  get finished(): boolean {
    return this.allSearchesInStatuses(FINISHED_SEARCH_STATUSES);
  }

  get accessible(): boolean {
    return this.allSearchesInStatuses(ACCESSIBLE_SEARCH_STATUSES);
  }

  get isPartiallyAccessible(): boolean {
    return this.someSearchInStatus(ACCESSIBLE_SEARCH_STATUSES);
  }

  get failed(): boolean {
    return this.status === SearchStatus.Failed;
  }

  get status(): SearchStatus {
    if (this.someSearchInStatus(SearchStatus.Running)) {
      return SearchStatus.Running;
    }

    if (this.someSearchInStatus(SearchStatus.Pending)) {
      return SearchStatus.Pending;
    }

    if (this.allSearchesInStatuses(SearchStatus.Failed)) {
      if (this.someSearchInStatus(SearchStatus.Deferred)) {
        return SearchStatus.Deferred;
      }
      return SearchStatus.Failed;
    }

    if (this.someSearchInStatus(SearchStatus.Deferred)) {
      return SearchStatus.Deferred;
    }

    return SearchStatus.Completed;
  }
}
