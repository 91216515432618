import {Serializer} from '@matchsource/api-utils';
import {ValidatedTyping} from '@matchsource/api-generated/subject';
import {PatientHlaLocusValidityModel} from '@matchsource/models/patient';

export const patientHlaLocusValiditySerializer: Serializer<PatientHlaLocusValidityModel[], ValidatedTyping[]> = {
  fromDTO(input: ValidatedTyping[]): PatientHlaLocusValidityModel[] {
    if (!input) {
      return [];
    }

    return input.map(validatedTyping => ({
      locus: validatedTyping.locus,
      typeNumber: validatedTyping.typeNumber,
      typeValue: validatedTyping.typeValue,
      typing: validatedTyping.typing,
      validity: !!validatedTyping.validity,
    }));
  },
};
