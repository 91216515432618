import {Serializer} from '@matchsource/api-utils';
import {Hla} from '@matchsource/api-generated/subject';
import {PatientHlaLocusModel} from '@matchsource/models/patient';
import {coerceBoolean} from '@matchsource/utils';

export const patientHlaTypingSerializer: Serializer<PatientHlaLocusModel[], Hla[]> = {
  fromDTO(input: Hla[]): PatientHlaLocusModel[] {
    if (!input) {
      return [];
    }

    return input.map(hla => ({
      name: hla.locus,
      type1: hla.type1,
      type1Formatted: hla.type1Method,
      type2: hla.type2,
      type2Formatted: hla.type2Method,
      glstring: hla.glorEmpty?.toString() || 'false',
      glstringInd: hla.glstringInd || false,
    }));
  },

  toDTO(input: PatientHlaLocusModel[]): Hla[] {
    if (!input) {
      return [];
    }

    return input.map(hla => ({
      locus: hla.name,
      type1: hla.type1,
      type1Method: hla.type1Formatted,
      type2: hla.type2,
      type2Method: hla.type2Formatted,
      glstringInd: hla.glstringInd,
      glorEmpty: coerceBoolean(hla.glstring),
    }));
  },
};
