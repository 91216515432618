import {Serializer} from '@matchsource/api-utils';
import {AdvancedPatientLookupDataSetModel, AdvancedPatientLookupModel} from '@matchsource/models/patient';
import {createPaginatedDataSet} from '@matchsource/api-utils';
import {advancedPatientLookupSerializer} from './advanced-patient-lookup.serializer';
import {AdvancedPatientLookupDTO, AdvancedPatientLookupResponse} from './declarations';

export const advancedPatientLookupSetSerializer: Serializer<
  AdvancedPatientLookupDataSetModel,
  AdvancedPatientLookupResponse
> = {
  fromDTO(input: AdvancedPatientLookupResponse): AdvancedPatientLookupDataSetModel {
    return createPaginatedDataSet<AdvancedPatientLookupModel, AdvancedPatientLookupDTO>(
      input,
      advancedPatientLookupSerializer
    );
  },
};
