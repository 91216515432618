import {Serializer} from '@matchsource/api-utils';
import {TransplantTimelineHistoryModel} from '@matchsource/models/patient';
import {fullName} from '@matchsource/api-utils';
import {TransplantTimelineHistory} from '@matchsource/api-generated/subject';

export const transplantTimelineHistorySerializer: Serializer<
  TransplantTimelineHistoryModel,
  TransplantTimelineHistory
> = {
  fromDTO(input: TransplantTimelineHistory): TransplantTimelineHistoryModel {
    return {
      code: input.code,
      date: input.date,
      updatedBy: fullName(input.nameOfPersonUpdatedBy),
    };
  },
};
