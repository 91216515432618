import {Injectable} from '@angular/core';
import {Serializer} from '@matchsource/api-utils';
import {BundleSearch} from '@matchsource/api-generated/search-match';
import {SearchModel, SearchStatusModel} from '@matchsource/models/search';

@Injectable({
  providedIn: 'root',
})
export class SearchSerializer implements Serializer<SearchModel, BundleSearch> {
  fromDTO(input: BundleSearch): SearchModel {
    return {
      abOnly: input.abOnly,
      maintenance: input.maintenance,
      patientId: input.patientId,
      phenotype: input.phenotypeNum,
      parameters: input.parameters,
      searchGuid: input.searchGuid,
      sourceSearches: input.sourceSearches as SearchStatusModel[],
    };
  }
}
