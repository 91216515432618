import {Serializer} from '@matchsource/api-utils';
import {PotentialTypingResult, TypingFrequency, ReportedTypingResult} from '@matchsource/api-generated/subject';
import {
  PotentialTypingResultModel,
  PotentialTypingsModel,
  TypingFrequencyModel,
} from '@matchsource/models/frequency-data';
import {hlaSerializer} from '@matchsource/api/hla';
import {HlaMapModel} from '@matchsource/models/hla';

const mapPotentialTyping = (input: PotentialTypingResult): PotentialTypingResultModel => ({
  percent: input.percent,
  typings: Object.entries(input.typings).reduce((typings: PotentialTypingsModel, [locus, value]) => {
    typings[locus] = {locus, antigen1: value.antigen1, antigen2: value.antigen2};
    return typings;
  }, {}),
});

const mapReportedTypings = (input: Record<string, ReportedTypingResult>, phenotypeNum: number): HlaMapModel =>
  Object.entries(input || {}).reduce(
    (ptr: HlaMapModel, [locus, value]) => {
      const item = hlaSerializer.fromDTO({locus, ...value});
      ptr[locus] = item;
      ptr.list.push(item);
      return ptr;
    },
    {index: phenotypeNum, list: []}
  );

export const typingFrequencySerializer: Serializer<TypingFrequencyModel, TypingFrequency> = {
  fromDTO(input: TypingFrequency, phenotypeNum: number): TypingFrequencyModel {
    return {
      potentialTypings: input.potentialTypings.map(potentialTyping => mapPotentialTyping(potentialTyping)),
      reportedTypings: mapReportedTypings(input.reportedTypings, phenotypeNum),
    };
  },
};
